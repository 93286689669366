<template>
  <div class="swiper-outer">
    <div class="swiper-wrap" :style="'transform:' + transVal">
      <div class="swiper-item" v-for="(item, index) in imgs" :key="index">
        <img :src="item.banner" :alt="item.name" style="width:100%">
      </div>
    </div>
    <div class="dots-wrap">
      <div class="dot" :class="count - 1 == index ? 'on' : ''" v-for="(item, index) in banners" :key="index"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['banners'],
  data() {
    return {
      imgs: [],
      swiperWidth: 500,
      bannerImgWidth: 150,
      transX: 0,
      transVal: 0,
      count: 0
    };
  },
  mounted() {
    console.log("mounted ", this.$props.banners);
    this.imgs = this.$props.banners.map(item => {
      return {
        banner: this.domin + item.Images_Url,
        name: item.Images_ID
      }
    })
    setInterval(() => {
      if (this.count == this.banners.length) {
        this.count = 0;
      }
      this.transVal = "translateX(-" + 100 * this.count + "%)";
      this.count += 1;

      //  console.log("transVal:",this.transVal)
    }, 5000);
  }
};
</script>

<style scoped>
.dots-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  left: 0;
  bottom: 10px;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.65);
  margin: 0 1.5px;
  transition: all 300ms ease-in-out;
}

.dot.on {
  width: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}

.swiper-outer {
  width: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;
}

.swiper-wrap {
  width: 100%;
  transition: all 600ms ease;
  display: flex;
}

.swiper-item {
  flex: none;
  width: 100%;
  overflow: hidden;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;
}</style>
